import { Button3 } from "./Button";

const CtaSection = () => {
  return (
    <>
    
      <section className="hero dark:bg-gray-900">
        <div className="pt-8 pb-8 px-4 mx-auto max-w-screen-xl sm:pt-16 sm:pb-8 lg:px-6">
          <div className=" text-left sm:mx-auto  sm:text-center">
            <h1 className="mb-4 font-extrabold text-step-6 sm:font-bold  sm:text-customblue text-black tracking-tight  leading-tight  dark:text-white">
              Become Our Client!
            </h1>
            <p className=" test text-gray dark:text-gray-400 md:text-lg text-step-0 sm:text-step--3">
              Do you have a project and would love to have our expertise on it?
              Click the link below to schedule and appointment with us.
            </p>
            <p className="mb-8 text-step-0 sm:text-step--3">
              You can also mail us on hello@babskenky.com
            </p>
            <Button3 />
          </div>
        </div>
      </section>
    </>
  );
};
export default CtaSection;
