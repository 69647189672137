export const Button1 = () => {
  return (
    <a
      href="mailto:hello@babskenky.com"
      className="text-white font-bold bg-customblue py-4 px-10 rounded-tr-lg rounded-bl-lg"
    >
      Let's Talk
    </a>
  );
};

export const Button2 = () => {
  return (
    <button className="border-blue-900 border-2 py-3 px-8 rounded-tr-lg rounded-bl-lg">
      <a
        href="mailto:hello@babskenky.com"
        className="block py-2 pl-3 pr-3 text-customblue text-step-0 sm:text-step--3 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent  md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
      >
        Let's Talk
      </a>
    </button>
  );
};

export const Button3 = () => {
  return (
    <a
      href="mailto:hello@babskenky.com"
      className="text-customblue bg-gold py-4 px-10 rounded-tr-lg rounded-bl-lg shadow-2xl  font-bold"
    >
      Let's Talk
    </a>
  );
};
