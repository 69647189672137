import bgTitle from "../public/images/bg-title.png";
const PrivacyPolicy = () => {
  return (
    
    <section className="pt-32 md:pt-40 w-80  md:w-9/12 mx-auto text-step--1 md:text-step--4">
      <div
        className="text-center pb-4 pt-8 "
        style={{ background: `url(${bgTitle})` }}
      >
        <h1 className="mb-4 font-extrabold text-step-2 sm:font-bold  text-white tracking-tight  leading-tight">
          Privacy Policy
        </h1>
        <h4 className="text-white">Last Updated : October 11, 2024</h4>
      </div>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">Introduction</h2>
        <p className="text-gray-600">
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Interpretation and Definitions</h2>
        <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Interpretation</h3>
        <p className="text-gray-600 mb-4">
          The words of which the initial letter is capitalized have meanings defined under the following conditions.
        </p>
        <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Definitions</h3>
        <ul className="list-disc pl-5 text-gray-600">
          <li>Account: A unique account created for You to access our Service or parts of our Service.</li>
          <li>Company: Refers to Babskenky Consulting, 27222 Fulshear Bend Drive, Fulshear TX 77441.</li>
          <li>Cookies: Small files placed on Your device by a website.</li>
          <li>Country: Refers to Texas, United States</li>
          <li>Personal Data: Any information that relates to an identified or identifiable individual.</li>
          <li>Service: Refers to the Website.</li>
          <li>Website: Refers to Babskenky Consulting, accessible from https://www.babskenky.com</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Collecting and Using Your Personal Data</h2>
        <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Types of Data Collected</h3>
        <p className="text-gray-600 mb-2">Personal Data may include:</p>
        <ul className="list-disc pl-5 text-gray-600 mb-4">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </ul>
        <p className="text-gray-600">
          Usage Data is collected automatically when using the Service. It may include information such as Your Device's Internet Protocol address, browser type, browser version, pages of our Service that You visit, time and date of Your visit, time spent on those pages, unique device identifiers and other diagnostic data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Use of Your Personal Data</h2>
        <p className="text-gray-600">
          The Company may use Personal Data for purposes such as:
        </p>
        <ul className="list-disc pl-5 text-gray-600">
          <li>Providing and maintaining our Service</li>
          <li>Managing Your Account</li>
          <li>Contacting You</li>
          <li>Providing news, special offers and general information about other goods, services and events</li>
          <li>Managing Your requests</li>
          <li>For business transfers</li>
          <li>For other purposes such as data analysis, identifying usage trends, and improving our Service</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Retention of Your Personal Data</h2>
        <p className="text-gray-600">
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Transfer of Your Personal Data</h2>
        <p className="text-gray-600">
          Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Delete Your Personal Data</h2>
        <p className="text-gray-600">
          You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You. Our Service may give You the ability to delete certain information about You from within the Service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Disclosure of Your Personal Data</h2>
        <p className="text-gray-600">
          We may disclose Your Personal Data in situations such as business transactions, law enforcement requirements, and other legal obligations.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Security of Your Personal Data</h2>
        <p className="text-gray-600">
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Children's Privacy</h2>
        <p className="text-gray-600">
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Links to Other Websites</h2>
        <p className="text-gray-600">
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Changes to this Privacy Policy</h2>
        <p className="text-gray-600">
          We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
        <p className="text-gray-600 pb-8">
          If you have any questions about this Privacy Policy, You can contact us by email: hello@babskenky.com
        </p>
      </section>
    </section>
  );
};

export default PrivacyPolicy;
