import React from 'react';
import {createBrowserRouter, Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import './App.css';
import Navbar from './components/Navbar';
import Hero from 'components/Hero';
import "./index.css"
import Services from 'components/Services';
import Footer from 'components/Footer';
import OurStory from 'components/OurStory';
import Reviews from 'components/Reviews';
import Projects from 'components/Projects';
import SidebarNav from 'components/SideBar';
import { SidebarProvider } from 'components/Context/SideBar';
import MainLayout from 'MainLayout';
import HomePage from 'pages/HomePage';
import RefundPage from 'pages/RefundPage';
import PrivacyPolicy from 'pages/PrivacyPolicy';
const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout/>,
   children:[
    {
      path:'/',
      element:<HomePage/>
    },
    {
      path:'/refund-policy',
      element:<RefundPage/>
    },
    {
      path:'/privacy-policy',
      element:<PrivacyPolicy/>
    } ,
   ] 
  },
]);

export default AppRouter;
 