import bgTitle from "../public/images/bg-title.png";
const RefundPage = () => {
  return (
    <section className="pt-32 md:pt-40 w-80  md:w-9/12 mx-auto text-step--1 md:text-step--4">
      <div
        className="text-center pb-4 pt-8 "
        style={{ background: `url(${bgTitle})` }}
      >
        <h1 className="mb-4 font-extrabold text-step-2 sm:font-bold  text-white tracking-tight  leading-tight">
          Refund Policy
        </h1>
        <h4 className="text-white">Last Updated: 9th of September, 2024</h4>
      </div>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">1. Project Cancellation</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">If the client cancels the project before work has begun, a full refund will be provided minus any non-refundable deposit.</li>
          <li>If the client cancels the project after work has begun, refunds will be prorated based on the percentage of work completed.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">2. Dissatisfaction with Deliverables</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">If the client is dissatisfied with the final deliverables, they must notify us in writing within 7 days of project completion.</li>
          <li className="mb-2">We will work to address any issues that fall within the original project scope at no additional cost.</li>
          <li>If issues persist after reasonable attempts to rectify them, a partial refund may be considered on a case-by-case basis.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">3. Scope Changes</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">Changes to project scope may affect the refund policy. Any modifications must be agreed upon in writing.</li>
          <li>Additional work beyond the original scope is not eligible for refunds.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">4. Non-Refundable Items</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">Initial consultation fees</li>
          <li className="mb-2">Custom research and planning costs</li>
          <li>Third-party licenses or services purchased for the project</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">5. Refund Process</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">All refund requests must be submitted in writing to <a href="mailto:hello@babskenky.com" className="text-blue-600 hover:underline">hello@babskenky.com</a>.</li>
          <li className="mb-2">Approved refunds will be processed within 14 business days.</li>
          <li>Refunds will be issued using the original payment method when possible.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 my-4">6. Dispute Resolution</h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li className="mb-2">In the event of a dispute, both parties agree to enter into good-faith negotiations before pursuing legal action.</li>
          <li>If an agreement cannot be reached, arbitration will be used as specified in our service agreement.</li>
        </ul>
      </section>

      <p className="text-gray-600 my-8">
        We reserve the right to modify this refund policy at any time. The current version will always be available on our website (<a href="https://www.babskenky.com" className="text-blue-600 hover:underline">www.babskenky.com</a>).
      </p>
    </section>
  );
};

export default RefundPage;
