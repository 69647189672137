import CtaSection from "components/CtaSection";
import Hero from "components/Hero";
import OurStory from "components/OurStory";
import Projects from "components/Projects";
import Reviews from "components/Reviews";
import Services from "components/Services";
import SidebarNavigation from "components/SideBar";

const HomePage = () => {
  return (
    <>
      <Hero />
      
      <Services />
      <Projects />
      <OurStory />
      <Reviews />
      <CtaSection/>
    </>
  );
};

export default HomePage;
