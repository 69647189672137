import { useRef,useEffect } from 'react'
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router-dom'

export interface NavItemProps{
 to:string;
 name:string;
}

export const NavItem = ({to,name}:NavItemProps) => {
    const location = useLocation();
    const elementRef = useRef<HTMLAnchorElement | null>(null);
  
    useEffect(() => {
        console.log(location.hash)
        if (location.hash === to) {
            
            const targetElement = document.querySelector(to);
            if (targetElement) {
              targetElement.scrollIntoView({ behavior: 'smooth' ,block: 'start'});
            }
        
          }
    }, [location, to]);
  
    return (
       <Link ref={elementRef}
      key={to}
      to={'/'+to}
      className='block  pl-3 pr-4 text-black text-step-0 sm:text-step--3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"'
    >
      {name}
    </Link>
    );
  };