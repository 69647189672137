import React from "react";
import icon1 from "../public/images/Group 57.png";
interface ServiceCardProps {
  src: string;
  title: string;
  text: string;
  bgColor: string;
}
const ServiceCard: React.FC<ServiceCardProps> = ({
  src,
  title,
  text,
  bgColor,
}) => {
  const bgcolor = `var(--${bgColor})`;

  return (
    <>
      <div
        className={`${bgcolor} flex-col justify-center items-center p-4 h-full rounded-lg`}
        style={{ background: bgcolor }}
      >
        <div className="flex pb-4 items-center">
          <img src={src ? src : icon1} width={50} />
          <h3 className="text-step-0 sm:text-step--3 pl-4 text-black">
            {title ? title : "Strategy Consultancy"}
          </h3>
        </div>
        <div>
          <p className="text-step-0 sm:text-step--3">
            {text
              ? text
              : "We strategise with you to come up with the best solution, tailor-fit for your business needs."}
          </p>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
