import { SidebarProvider } from "components/Context/SideBar";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import ScrollToTop from "components/ScrollToTop";
import SidebarNavigation from "components/SideBar";
import { Outlet } from "react-router-dom";

const MainLayout = () => (
    <>
    <ScrollToTop />
  <SidebarProvider>
    <SidebarNavigation />
    <div className="mx-auto">
      <Navbar />
      <div className="main ">
        <Outlet />
      </div>
      <Footer />
    </div>
  </SidebarProvider>
    </>
   
);

export default MainLayout;
