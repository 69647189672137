import { useEffect, useState, useRef, useCallback } from "react";
import Eatwell from "../public/images/Eatwell.png";
import HealthCare from "../public/images/healthcare.png";
import Daisies from "../public/images/daisies.png";
import SelectGlobal from "../public/images/selectglobal.png";
import ProjectCard from "./ProjectCard";
import { Button2 } from "./Button";
import "../styles/Projects.css";


import { useInView } from "react-intersection-observer";

type Project = {
	id: string;
	title: string;
	description: string;
	imageSrc: string;
};

const project: Project[] = [
	{
		id: "01",
		title: "Eatwell Philly",
		description:
			"Eatwell Philly is a restaurant plus catering service located in Philadelphia, USA. We created a platform to make it easier for customers to see the catalogue of foods from the comfort of the homes and also place an order. Their platform also helps to take details of customers who want their catering and events service. The last feature of the platform is to make their hiring process easy for potential jobseekers.",
		imageSrc: Eatwell,
	},
	{
		id: "02",
		title: "Caring Heart Healthcare",
		description: `Caring Heart is a US-based healthcare facility. 
        We created a platform that creates an opportunity  for potential clients to locate them on the internet. Their website also has a section where jobseekers can drop their job application without having to take paper-based application to the physical office.`,
		imageSrc: HealthCare,
	},
	{
		id: "03",
		title: "Select Global Foods LLC",
		description: `Select Global Foods is an African-owned grocery store in the heart of New Jersey where you can get your groceries ranging from african imports to the most available grocery on the American soil. Their ecommerce store allows customers to place their order and have it delivered to their doorstep or come pick it up at the physical store themselves. `,
		imageSrc: SelectGlobal,
	},
	{
		id: "05",
		title: "Blooming Daisies Pre-School",
		description: `Blooming Daisies is a childcare facility. We created a platform that allows parents to schedule an appointment with the management of the learning centre and also to get to know about what the learning centre is all about. `,
		imageSrc: Daisies,
	},
];
const Projects = () => {
	const { ref, inView } = useInView();
	const [isMobile, setIsMobile] = useState<Boolean>(true);
	const [projects, setProjects] = useState<Project[]>(project);
	const projectRef = useRef<Boolean>(false);
	const observer = useRef<IntersectionObserver | null>(null);
	const options = {
		root: null,
		rootMargin: "0px",
		threshold: 0.1,
	};
	function resizeHandler() {
		const isMobile: boolean =
			Math.min(window.screen.width, window.screen.height) < 768 ||
			navigator.userAgent.indexOf("Mobi") > -1;
		isMobile ? setIsMobile(true) : setIsMobile(false);
	}
	const imageRef = useCallback(
		(node: HTMLImageElement) => {
			if (isMobile) return;
			if(!node) return ;
			const animateStyle = node.getAttribute('animate-style') === 'fade-right'?'fadeInLeft':'fadeInRight';
			//if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				//console.log(entries);
				
				if (entries[0].isIntersecting && !isMobile) {
					
					if(entries[0].target.classList.contains(animateStyle)){
						entries[0].target.classList.remove(animateStyle);
					}
                     
					 entries[0].target.classList.add(animateStyle)
				}
			},options);
			if (node) observer.current.observe(node);
			
		},
		[isMobile]
	);

	useEffect(() => {
		resizeHandler();
		

		const resize = window.addEventListener("resize", resizeHandler);

		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, []);
	return (
		<>
			<section className="container-center pt-12 work " id="projects">
				<h1 className="font-extrabold text-left  text-black sm:text-center sm:text-customblue text-step-5 sm:font-bold sm:text-step-3 pr-4 sm:pr-0 ">
					Some of our Client Projects
				</h1>
				<p className="text-step-2 sm:text-step--1 text-left  sm:text-center pt-4 sm:pb-24">
					We have done low-scale and high-scale projects across various
					industries
				</p>

				{!isMobile && (
					<div className="border-customblue border-b-2 border-opacity-0" style={{overflow:'hidden'}}>
						{projects.map((item, index) => {
							let alternatePosition: boolean = !projectRef.current;
							if (alternatePosition) {
								projectRef.current = alternatePosition;
								return (
									<div key={item.id} className="projects">
										<div className=" h-full border-customblue border-t-2 border-r-2  project py-8 pr-8 ">
											<h3 className="font-bold text-step--1">{item.title}</h3>
											<p className="pt-4 text-step--4">{item.description}</p>
										</div>
										<div className="h-full  border-customblue border-t-2   project p-8">
											<div >
												<img
													ref={imageRef}
													src={item.imageSrc}
													animate-style="fade-left"
													className=''

												/>
												
											</div>
										</div>
									</div>
								);
							} else {
								projectRef.current = alternatePosition;
								return (
									<div className="projects" key={item.id}>
										<div className="h-full border-customblue border-t-2 border-r-2   project p-8">
											<div >
												<img
													ref={imageRef}
													src={item.imageSrc}
													animate-style="fade-right"
													className=''
												/>
											</div>
										</div>
										<div className="h-full border-customblue border-t-2   project  py-8 pl-8">
											<div>
												<h3 className="font-bold text-step--1">{item.title}</h3>
												<p className="pt-4 text-step--4">{item.description}</p>
											</div>
										</div>
									</div>
								);
							}
						})}
					</div>
				)}

				{isMobile &&
					projects.map((item, index) => {
						return (
							<div key={item.id}>
								<ProjectCard
									imageSrc={item.imageSrc}
									description={item.description}
									title={item.title}
								/>
							</div>
						);
					})}
				<div className="flex flex-col items-center py-12">
					<p className="pb-8 text-step-0 sm:text-step--3  text-center ">
						Your own project can be our next success, let’s talk!
					</p>
					<Button2 />
				</div>
			</section>
		</>
	);
};

export default Projects;
