import "../styles/Reviews.css";
import rightArrow from "../public/images/arrow-left.png";
import leftArrow from "../public/images/arrow-right.png";
import Slider from "react-slick";
import { useEffect, useRef } from "react";

const Reviews = () => {
	const btnPrev = useRef<any>(null);
	const btnnext = useRef<any>(null);
	useEffect(() => {
		const prev = document.querySelector(".slick-prev");
		const next = document.querySelector(".slick-next");
		btnPrev.current = prev;
		btnnext.current = next;
	}, []);
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};
	return (
	
			<section className="bg-customblue  py-28" id="testimonials">
				<div className="container-center">
					<h1 className="text-step-5 text-left sm:text-step-3   pb-12 sm:text-center  text-white font-extrabold sm:font-bold">
						Client Reviews
					</h1>
					<div className="reviews ">
						<div className="h-full border-lightblue border-t-2 border-b-2  min-w-0 flex items-center justify-center">
							<img
								src={leftArrow}
								onClick={() => {
									btnPrev.current.click();
								}}
							/>
						</div>
						<div
							className="px-2  border-lightblue border-2 reviews-section  py-12 sm:py-28 sm:px-6 "
							style={{ overflow: "hidden" }}
						>
							<Slider {...settings}>
								<div className="w-full px-2">
									<p className="text-white text-step--1 sm:text-step--3">
										Meeting with the Babskenky team changed my business. I was
										able to reach a wider range of customers than I was doing
										before. The strategies they provided works like magic. I’m
										glad I met with them and I’d heartily recommend their
										service. They are the best at what they do!
									</p>
									<h3 className="text-white text-step-0 sm:text-step--1 pt-24">
										Mr Aderemi Adiooo
									</h3>
									<p className="text-white text-step-0 sm:text-step--3">
										Proprietor, Glodagrem HealthCare
									</p>
								</div>
								<div className="w-full px-2">
									<p className="text-white text-step--1 sm:text-step--3">
										Meeting with the Babskenky team changed my business. I was
										able to reach a wider range of customers than I was doing
										before. The strategies they provided works like magic. I’m
										glad I met with them and I’d heartily recommend their
										service. They are the best at what they do!
									</p>
									<h3 className="text-white text-step-0 sm:text-step--1 pt-24">
										Alhaja Saliat Sanusi
									</h3>
									<p className="text-white text-step-0 sm:text-step--3">
										Adlat Catering Enterprises
									</p>
								</div>
							</Slider>
						</div>
						<div className="h-full border-lightblue  border-t-2 border-b-2 flex items-center justify-center  ">
							<img
								src={rightArrow}
								onClick={() => {
									btnnext.current.click();
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		
	);
};

export default Reviews;
