import "../styles/Hero.css";

import {Button1} from "./Button";
import Banner from "./Banner";
import eccentric from "../public/images/eccentric.png";
import arrow from "../public/images/arrow.png";
import paymentCards from  "../public/images/all-major-credit-cards.png"
const Hero = () => {
	return (
		
			<section className=" relative flex  justify-center ">
				
                <div className="   hero pt-12  w-full sm:pb-24 2xl:pb-2 ">
                 <div className="  -z-50 container-center flex flex-col content-between justify-around  items-center lg:flex-row  pt-24 ">
                    
                <div className="text-left">
					<h1 className="text-customblue text-step-6 font-extrabold  ">
						Web Design.{" "}
						<span
							className="text-gold"
							style={{ fontSize: "inherit", fontWeight: "bold" }}
						>Digital Transformation.
							<br />
							Digital Strategy.
						</span>{" "}
						Marketing.
					</h1>
					<p className="text-step-0 sm:text-step--3 " >
						At Babskenky, we don’t just make websites, we design experiences.
					</p>
					<div className="py-8">
						<Button1 />
					</div>
					<p className=" w-4/5 sm:w-1/2 text-step-0 sm:text-step--4 ">
					We accept these cards
						</p>
					<div><img src={paymentCards} alt="Payment cards" width={150}/></div>
					
					
				</div>
				<div className=" pb-6 pr-4 lg:w-6/12 md:p-24">
					<img
						src={eccentric}
						alt="hero"
						className="max-w-full h-auto "
					/>
				</div>
                </div>
                 </div>
				<Banner />
                
			</section>
		
	);
};

export default Hero;