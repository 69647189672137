import arrowSide from "../public/images/arrowside.png";
import '../styles/OurStory.css';
const OurStory = () => {
	return (
		
			<section className=" bg-radialgold pt-8 pb-24 about  " id="about">  
                <div className="story sm:hidden container-center flex justify-between pt-8 ">
                <div className="">
						<h1 className="text-step-5  font-extrabold  text-black text-left">Our Story</h1>
						<div className="sm:w-4/5 pt-4">
							<p className="text-left text-step-0 leading-6 ">
								We are a consulting agency founded with the aim of bringing a
								fresh and contemporary approach to bringing solutions to your
								business needs. Our expertise cuts across various industries
								from e-commerce to logistics to health to education to finance
								and much more.
							</p>
							<p className="text-left text-step-0 leading-6 pt-8">
								Our work process is structured in an easy way such that we are
								able to bring clear strategies that will not only increase your
								conversion rate but also make your customers patronise you in
								the long term.
							</p>
							<p className="text-left text-step-0 leading-6 pt-8">
								At Babskenky, we are committed to ensuring that your business
								not only prospers but that your customers are happy with your
								stack of services and want more...
							</p>
						</div>
					</div>
                </div>
				<div className="hidden  container-center sm:flex justify-between pt-8  ">
					<div className="">
						<h1 className="text-step-3  text-customblue text-left ">Our Story</h1>
						<div className="w-4/5 pt-4">
							<p className="text-left sm:text-step--3 ">
								We are a consulting agency founded with the aim of bringing a
								fresh and contemporary approach to bringing solutions to your
								business needs. Our expertise cuts across various industries
								from e-commerce to logistics to health to education to finance
								and much more.
							</p>
							<p className="text-left text-step-0 sm:text-step--3 py-2">
								Our work process is structured in an easy way such that we are
								able to bring clear strategies that will not only increase your
								conversion rate but also make your customers patronise you in
								the long term.
							</p>

							<p className="text-left text-step-0 sm:text-step--3">
								At Babskenky, we are committed to ensuring that your business
								not only prospers but that your customers are happy with your
								stack of services and want more...
							</p>
						</div>
					</div>
					<div>
						<img src={arrowSide} alt="story image" />
					</div>
				</div>
			</section>
		
	);
};

export default OurStory;
