import { Link } from "react-router-dom";

const Footer = () => {
  return (
  
      <footer className="text-center hero z-10 ">
        <p className="text-step--6 pt-10  pb-4 text-center">
          ©2024. All rights reserved.
        </p>
		<ul className="flex justify-center gap-2">
		 <Link to='/refund-policy' className="text-step--6 text-grey underline"  >Refund Policy</Link>
			<Link to='/privacy-policy' className="text-step--6 text-grey underline" >Privacy Policy</Link>
			
		</ul>
        <p className="text-step--6 text-center pb-4">
          Built with love by Babskenky & Company Inc.
        </p>
      </footer>
    
  );
};

export default Footer;
